import { Controller } from '@hotwired/stimulus';

// This controller needs some refactoring: https://remarkgroup.atlassian.net/browse/BRMS-3642
export default class extends Controller {
  static targets = ['button', 'input'];

  connect() {
    setTimeout(() => {
      this.update();
    }, 1);
  }
  update() {
    let inputMissing = false;
    for (const inputTarget of this.inputTargets) {
      if (!inputTarget.value) {
        inputMissing = true;
        break;
      }
    }
    if (this.hasButtonTarget) {
      if (inputMissing) {
        this.buttonTarget.setAttribute('disabled', 'true');
        this.buttonTarget
          .querySelector('brms-title')
          .setAttribute('color', 'balanced-500');
      } else {
        this.buttonTarget.removeAttribute('disabled');
        this.buttonTarget
          .querySelector('brms-title')
          .setAttribute('color', 'light');
      }
    }
  }
}
