export default function turboFetch(
  endpoint,
  method = 'GET',
  body = '',
  signal = null,
  callback = null
) {
  const options = {
    headers: {
      Accept: 'text/vnd.turbo-stream.html',
    },
    method: method,
    signal: signal,
  };

  if (method !== 'GET' && method !== 'HEAD') {
    options.body = body;
  }

  fetch(endpoint, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.text();
    })
    .then((html) => {
      Turbo.renderStreamMessage(html);
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      console.error('Fetch error:', error);
    });
}
