import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    completedUrl: String,
  };

  initialize() {
    this.handleCompletedEvent = this.handleCompletedEvent.bind(this);
  }

  connect() {
    document.addEventListener(
      'rmv-widget:completed',
      this.handleCompletedEvent
    );
  }

  disconnect() {
    document.removeEventListener(
      'rmv-widget:completed',
      this.handleCompletedEvent
    );
  }

  handleCompletedEvent(e) {
    get(this.completedUrlValue, {
      responseKind: 'turbo-stream',
    });
  }
}
