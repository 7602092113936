import TableVisibilityController from './table_visibility_controller';

export default class extends TableVisibilityController {
  static targets = ['addRuleButton', ...TableVisibilityController.targets];

  updateLinkedRuleIds() {
    setTimeout(() => {
      const ruleIds = this.tableRowTargets
        .filter((row) => this.isRowVisible(row))
        .map((row) => row.getAttribute('data-rule-id'));

      const action = new URL(
        this.addRuleButtonTarget.getAttribute('action'),
        window.location.origin
      );
      action.searchParams.delete('linked_rule_ids[]');
      ruleIds.forEach((ruleId) =>
        action.searchParams.append('linked_rule_ids[]', ruleId)
      );

      this.addRuleButtonTarget.setAttribute('action', action.href);
    }, 10);
  }
}
