import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  initialize() {
    this.calculateHeights = this.calculateHeights.bind(this);
  }
  
  connect() {
    this.accordions = this.element.querySelectorAll('rmv-accordion');

    // Add event listeners for each accordion
    this.accordions.forEach((accordion, index) => {
      this.element.addEventListener(
        `rmv-accordion:toggle:filter-accordion_${index}`, this.calculateHeights
      );
    });

    window.addEventListener('resize', this.calculateHeights);
  }

  disconnect() {

    //Cleanu up
    window.removeEventListener('resize', this.calculateHeights);
    this.accordions.forEach((accordion, index) => {
      this.element.removeEventListener(
        `rmv-accordion:toggle:filter-accordion_${index}`, this.calculateHeights
      );
    });
  }

  calculateHeights() {
    // Get starting position of the element and compare it with the view
    const startPos = this.element.getBoundingClientRect().top;
    const screenHeight = window.innerHeight;
    const availableHeight = screenHeight - startPos;

    // Determine the total height of all closed accordions and create a margin of 100px from the bottom up
    let totalClosedHeight = 100;
    this.accordions.forEach((accordion) => {
      if (!accordion.open) {
        totalClosedHeight += accordion.getBoundingClientRect().height;
      }
    });

    // Calculate the available height for open accordions
    const availableOpenHeight = availableHeight - totalClosedHeight;

    // Find how many accordions are open
    const openAccordions = Array.from(this.accordions).filter(
      (accordion) => accordion.open
    );

    // Calculate the max height each open accordion can have
    const maxOpenAccordionHeight =
      openAccordions.length > 0
        ? availableOpenHeight / openAccordions.length
        : 0;

    // Set max height and overflow property for each accordion
    this.accordions.forEach((accordion) => {
      const columnOfAccordion = accordion.querySelector('rmv-column');
      if (accordion.open) {
        columnOfAccordion.style.maxHeight = `${maxOpenAccordionHeight - 62}px`;
        columnOfAccordion.style.overflow = 'auto';
      } else {
        columnOfAccordion.style.maxHeight = '';
        columnOfAccordion.style.overflow = 'hidden';
      }
    });
  }
}
