import NestedForm from '@stimulus-components/rails-nested-form';
import { newConfirmMethod } from './index';

export default class extends NestedForm {
  static targets = ['search'];

  static values = {
    messages: Object,
    childIndexPlaceholder: {
      type: String,
      default: 'NEW_RECORD',
    },
  };

  connect() {
    super.connect();
  }

  add(e) {
    e.preventDefault();

    const fromBlankSlate = e.currentTarget.dataset.from_blank_slate || false;

    if (!fromBlankSlate) {
      const content = this.#instantiateTemplate();
      this.targetTarget.insertAdjacentHTML('beforebegin', content);

      const event = new CustomEvent('rails-nested-form:add', { bubbles: true });
      this.element.dispatchEvent(event);

      if (this.hasSearchTarget) {
        this.searchTarget.disabled = true;
      }
    }

    this.triggerFormChange();
  }

  remove(e) {
    super.remove(e);
    if (this.hasSearchTarget) {
      this.searchTarget.disabled = true;
    }

    this.triggerFormChange();
  }

  async remove_with_confirmation(e) {
    const result = await newConfirmMethod(JSON.stringify(this.messagesValue));

    if (result) {
      super.remove(e);
    }
  }

  addInBetween(event) {
    // Disable clicking on the rmv-column if the 'Add row' button is disabled
    if (
      event.currentTarget.tagName === 'RMV-COLUMN' &&
      event.currentTarget.querySelector('rmv-button').disabled
    ) {
      return;
    }
    if (this.hasSearchTarget) {
      this.searchTarget.disabled = true;
    }
    event.preventDefault();
    const div = document.createElement('div');
    div.innerHTML = this.#instantiateTemplate();
    const target = event.currentTarget.closest(
      `rmv-row${this.wrapperSelectorValue}`
    );
    const table = target.closest('rmv-table');
    table.insertBefore(div.firstElementChild, target);

    // Update the sequence number
    const sequenceInputs = table.querySelectorAll(
      'input[data-sortable-target]'
    );
    sequenceInputs.forEach((input, index) => {
      input.value = index;
    });

    this.triggerFormChange();
  }

  triggerFormChange() {
    const form = this.element.closest('form');
    if (form) {
      form.dispatchEvent(new Event('change'));
    }
  }

  #instantiateTemplate() {
    return this.#replaceChildIndex(this.templateTarget.innerHTML);
  }

  #replaceChildIndex(fragment) {
    return fragment.replace(
      this.#childIndexPlaceholderRegex(),
      new Date().getTime().toString()
    );
  }

  #childIndexPlaceholderRegex() {
    return new RegExp(this.childIndexPlaceholderValue, 'g');
  }
}
