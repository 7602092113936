import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
 
export default class extends Controller {
  static targets = ['orderParam'];
 
  order(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const column = button.dataset.orderColumn;
    const currentDirection = button.dataset.orderDirection || '';
 
    const directions = ['', 'asc', 'desc'];
    const nextDirection = directions[(directions.indexOf(currentDirection) + 1) % directions.length];
    button.dataset.orderDirection = nextDirection;
 
    const orderValue = nextDirection === '' ? '' : `${column} ${nextDirection}`;
    const orderParamTargetId = event.currentTarget.getAttribute('data-order-target-id') || this.orderParamTarget.id
    const orderParamTarget = this.orderParamTargets.filter(target => target.id === orderParamTargetId)[0]
    orderParamTarget.value = orderValue;
 
    // Use Rails request.js get to make a Turbo Stream request
    // since `orderParamTarget.form.requestSubmit();` and turboFetch did not
    // always reliably work
    const form = orderParamTarget.form;
    get(form.action + '?' + new URLSearchParams(new FormData(form)), {
      responseKind: 'turbo-stream'
    });
  }
}