import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ['search', 'sortAnswer'];
  static debounces = ['submit'];
  static values = {
    disableWhileSubmitting: Boolean,
  };

  initialize() {
    if (window.scrollYPosition > 0) {
      window.scrollTo(0, window.scrollYPosition);
      window.scrollYPosition = 0;
    }
  }

  connect() {
    useDebounce(this, { wait: 300 });
    if (this.hasSearchTarget) {
      setTimeout(() => {
        this.searchTarget.hasAttribute('autofocus') &&
          this.searchTarget.focus();
      }, 100);
    }
  }

  sortAnswerChanged(event) {
    const selectedValue = event.target.value;
    this.sortAnswerTarget.value = selectedValue;
    this.element.requestSubmit();
  }

  regenerateApiKey() {
    document.getElementById('regenerate_api_key_field').value = 'true';
    const form = document.getElementById('setting_form');
    form.submit();
  }

  submit(event) {
    // This is a workaround to prevent the page from scrolling to the top when submitting a form
    if (this.element.hasAttribute('data-turbo-preserve-scroll')) {
      window.scrollYPosition = window.scrollY;
    }
    // Disables all form fields if disableWhileSubmittingValue is true
    if (this.disableWhileSubmittingValue) {
      // This assumes that after the form is submitted, the page will be reloaded and therefor the pointer events will be reset
      this.element.style.pointerEvents = 'none';
      event.target.appendChild(this.spinner);
    }

    this.element.requestSubmit();
  }

  clear() {
    Turbo.visit(this.element.action);
  }

  get spinner() {
    const spinner = document.createElement('rmv-spinner');
    spinner.slot = 'suffix';
    return spinner;
  }
}
