import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];

  /**
   * Disables an element in response to a data event.
   *
   * @function disable
   */
  disable() {
    this.elementTargets.forEach((element) => {
      element.disabled = 'true';
    });
  }
}
