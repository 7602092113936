import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'divider'];

  connect() {
    this.hideButton();
    this.resetDividerColor();
  }

  showButton() {
    // If the button is disabled, don't show it
    if (this.buttonTarget.disabled) return;
    this.buttonTarget.classList.remove('hide-button-opacity');
    this.buttonTarget.classList.add('show-button-opacity');
    this.setDividerColor('rgba(var(--color-base-energized-700))');
  }

  hideButton() {
    this.buttonTarget.classList.remove('show-button-opacity');
    this.buttonTarget.classList.add('hide-button-opacity');
    this.resetDividerColor();
  }

  setDividerColor(color) {
    this.dividerTarget.style.setProperty('--divider-border-color', color);
  }

  resetDividerColor() {
    this.dividerTarget.style.removeProperty('--divider-border-color');
  }
}
