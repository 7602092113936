import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];
  static values = { attribute: String };

  /**
   * Gets the attribute value.
   * If the attribute value is not set, it defaults to 'open'.
   *
   * @returns {string} The attribute value or 'open' if not set.
   */
  get attribute() {
    return this.attributeValue || 'open';
  }

  /**
   * Shows all elements by setting the specified attribute to true for each target element.
   * Example: For a rmv-accordion this attribute is 'open'. But can be any attribute that is used to show/collapse elements using data-collapsible-attribute-value="..."
   *
   * @function
   * @name showAll
   * @memberof CollapsibleController
   */
  showAll() {
    this.elementTargets.forEach((element) => {
      element[this.attribute] = true;
    });
  }

  /**
   * Collapses all elements by setting the specified attribute to false for each target element.
   * Example: For a rmv-accordion this attribute is 'open'. But can be any attribute that is used to show/collapse elements.
   *
   * @function
   * @name collapseAll
   * @memberof CollapsibleController
   */
  collapseAll() {
    this.elementTargets.forEach((element) => {
      element[this.attribute] = false;
    });
  }
}
