import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['countedElement', 'counter'];

  update() {
    this.counterTarget.textContent = this.countedElementTargets.length;
  }

  // When removing an element, the actions bound to that element after the removal
  // will no longer be triggered. We are not sure why, but it may be due to the fact that
  // the target of the event is no longer present in the DOM, and as a result, the event
  // listener is no longer attached to the target. This is why we need to decrement the
  // count ahead of time before removing the element.
  // So instead of what we intended:
  //    click->nested-form#remove click->element-counter#update
  // We now use:
  //    click->element-counter#decrement click->nested-form#remove
  decrement() {
    this.counterTarget.textContent = this.countedElementTargets.length - 1;
  }
}
