// Import and register all your controllers from the importmap under controllers/*
import { application } from './application';

import FormController from './form_controller';
application.register('form', FormController);

import MountReactController from './mount_react_controller';
application.register('mount-react', MountReactController);

import ModalController from './modal_controller';
application.register('modal', ModalController);

import DynamicModalController from './dynamic_modal_controller';
application.register('dynamic-modal', DynamicModalController);

import SortableController from './sortable_controller';
application.register('sortable', SortableController);

import OrderController from './order_controller';
application.register('order', OrderController);

import NestedForm from './nested_form_controller';
application.register('nested-form', NestedForm);

import RegenerateController from './regenerate_controller';
application.register('regenerate', RegenerateController);

import VcRiskFactorRuleController from './vc-risk-factor-rule-controller';
application.register('vc-risk-factor-rule', VcRiskFactorRuleController);

import SpreadsheetUploadController from './spreadsheet_upload_controller';
application.register('spreadsheet-upload', SpreadsheetUploadController);

import SpreadsheetSearchController from './spreadsheet_search_controller';
application.register('spreadsheet-search', SpreadsheetSearchController);

import WidgetController from './widget_controller';
application.register('widget', WidgetController);

import inputConfirmationToggleController from './input_confirmation_toggle_controller';
application.register(
  'input-confirmation-toggle',
  inputConfirmationToggleController
);

import ClipboardController from './clipboard_controller';
application.register('clipboard', ClipboardController);

import EnableButtonController from './enable_button_controller';
application.register('enable-button', EnableButtonController);

import PreviewLoaderController from './preview_loader_controller';
application.register('preview-loader', PreviewLoaderController);

import CheckboxToggleController from './checkbox_toggle_controller';
application.register('checkbox-toggle', CheckboxToggleController);

import SelectToggleController from './select_toggle_controller';
application.register('select-toggle', SelectToggleController);

import ToggleController from './toggle_controller';
application.register('toggle', ToggleController);

import ToggleConfirmationController from './toggle_confirmation_controller';
application.register('toggle-confirmation', ToggleConfirmationController);

import PreviewTopbarController from './preview_topbar_controller';
application.register('preview-topbar', PreviewTopbarController);

import AsyncSelectController from './async_select_controller';
application.register('async-select', AsyncSelectController);

import TableOptionsController from './table_options_controller';
application.register('table-options', TableOptionsController);

import DiffController from './diff_controller';
application.register('diff', DiffController);

import FrameController from './frame_controller';
application.register('frame', FrameController);

import PaneController from './pane_controller';
application.register('pane', PaneController);

import SubsetMergeController from './subset_merge_controller';
application.register('subset-merge', SubsetMergeController);

import CodeEditorController from './code_editor_controller';
application.register('code-editor', CodeEditorController);

import FilterController from './filter_controller';
application.register('filter', FilterController);

import MirrorController from './mirror_controller';
application.register('mirror', MirrorController);

import SidebarController from './sidebar_controller';
application.register('sidebar', SidebarController);

import HighlightController from './highlight_controller';
application.register('highlight', HighlightController);

import ExplorerController from './explorer_controller';
application.register('explorer', ExplorerController);

import TableVisibilityController from './table_visibility_controller';
application.register('table-visibility', TableVisibilityController);

import TableButtonHoverController from './table_button_hover_controller';
application.register('table-button-hover', TableButtonHoverController);

import FieldValidatorController from './field_validator_controller';
application.register('field-validator', FieldValidatorController);

import ToggleHidden from './toggle_hidden_controller';
application.register('toggle-hidden', ToggleHidden);

import DirtyFormController from './dirty_form_controller';
application.register('dirty-form', DirtyFormController);

import ElementCounterController from './element_counter_controller';
application.register('element-counter', ElementCounterController);

import CollapsibleController from './collapsible_controller';
application.register('collapsible', CollapsibleController);

import SubsetRulesAddRuleController from './subset_rules_add_rule_controller';
application.register('subset-rules-add-rule', SubsetRulesAddRuleController);

import DisableElementController from './disable_element_controller';
application.register('disable-element', DisableElementController);

//overwrite data-turbo-confirm modal
const newConfirmMethod = async (message, formElement) => {
  const modal = document.getElementById('confirmation-modal');
  if (!modal) return;

  const title = modal.querySelector(
    '[data-selector="confirmation-modal-title"]'
  );
  const content = modal.querySelector(
    '[data-selector="confirmation-modal-content"]'
  );

  const cancelBtn = modal.querySelector(
    '[event-id="confirmation-modal-cancel"]'
  );

  const confirmBtn = modal.querySelector(
    '[event-id="confirmation-modal-confirm"]'
  );
  const strings = JSON.parse(message);
  title.textContent = strings.title;
  content.textContent = strings.description;
  cancelBtn && cancelBtn.setAttribute('value', strings.cancel);
  confirmBtn && confirmBtn.setAttribute('value', strings.confirm);
  modal.open = true;
  return new Promise((resolve, reject) => {
    document.addEventListener(
      `rmv-button:click:confirmation-modal-close`,
      () => {
        resolve(false);
        modal.open = false;
        formElement?.remove();
      }
    );
    document.addEventListener(
      `rmv-button:click:confirmation-modal-cancel`,
      () => {
        resolve(false);
        modal.open = false;
        formElement?.remove();
      }
    );
    document.addEventListener(
      `rmv-button:click:confirmation-modal-confirm`,
      () => {
        modal.open = false;
        resolve(true);
      }
    );
  });
};
export { newConfirmMethod };

Turbo.setConfirmMethod(newConfirmMethod);
